import React, { FunctionComponent } from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import Pill from "../../components/Pill"
import Container from "../../components/Container"
import { graphql } from "gatsby"
import ActionPanel from "../../components/home/ActionPanel"
import SignUpForm from "../../components/SignUpForm"
import Highlight from "../../components/home/Highlight"
import Grid from "../../components/Grid"
import Faq from "../../components/home/Faq"
import styles from "./index.module.css"
import BreezyLife from "../../components/home/BreezyLife"
import Callout from "../../components/home/Callout"

interface QueryResult {
  mdx: {
    frontmatter: {
      title: string
      seo: {
        title: string
        description: string
      }
    }
    body: string
  }
}

interface Props {
  data: QueryResult
}

const PiLivePage: FunctionComponent<Props> = ({ data }) => {
  const page = data.mdx
  // @ts-ignore
  return (
    <Layout>
      <Seo
        description={page.frontmatter.seo.description}
        pathname="/how-it-works"
        title={page.frontmatter.seo.title}
      />

      <header>
        <Pill bgClass="bg-black" variant="bottom-right">
          <Container style={{maxWidth: '85ch'}}>
            <div className="br-prose py-24 text-white">
              <div className="br-col-gap md:grid md:grid-cols-12">
                <div className="md:col-span-12 md:col-start-1 xxl:col-span-9 xxl:col-start-1">
                  <h1 className="mb-8 text-left">{page.frontmatter.title}</h1>
                  <div className="text-left">
                    {/*<p className={styles.subTitle}>How Breezy makes finding relevant partners quicker and <Highlight*/}
                    {/*  text="hassle-free."/></p>*/}
                    {/*<p className={styles.subTitle}></p>*/}
                    <p className={styles.subTitle}>Welcome to a world where discovering new content partners beyond existing networks<br/><Highlight text="is easy, fast and affordable!" /></p>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </Pill>
      </header>
      <section className="py-24 lg:py-40 mx-auto" style={{maxWidth: '100ch'}}>

        <section className="py-16 lg:py-24">
          <section className="px-6">
            <Container style={{maxWidth: '90ch'}}>
              <Grid>
                <section className="col-span-12 sm:pb-8 xl:col-span-6 xl:col-start-7 xl:pb-0">
                  <p className="mb-12">Searching with Breezy is super easy and gives you unrivalled insight into your potential partnership landscape.</p>
                  <p className="mb-12">We have put search at the heart of partner discovery, identifying partners in your market from across the web, not just existing networks.</p>
                  <p className="mb-12">That means more brand awareness and bigger revenue from partners that are more relevant and versatile.</p>
                </section>
                <section className="col-span-12 text-center row-start-1 mb-16 xl:mb-0 xl:row-start-0 xl:col-span-6 xl:col-start-1 xl:row-start-1 xl:text-left">
                  <img alt="Keyword-based search" src="./../images/about-us-main.svg"
                       className="inline-block relative mt-0 xl:mt-12"/>
                </section>
              </Grid>
            </Container>
          </section>
        </section>

        <section className="mb-20 px-6 md:mb-24">
          <Container>
            <Grid className="items-center">
              <section className="col-span-12 sm:pb-8 xl:col-span-5 xl:-mt-4 xl:pb-0 xxxl:col-start-2">
                <h2>Discover</h2>
                <p>
                  Find thousands of potential partners using Breezy’s whole web searches, tailored to your particular brand or market.
                </p>
              </section>

              <section className="col-span-12 xl:col-span-6 xl:col-start-7">
                <img alt="Discover potential partners" src="./../images/Discover.png" className="inline-block relative" style={{width: '100%'}}/>
              </section>
            </Grid>
          </Container>
        </section>

        <section className="mb-20 px-6 md:mb-24">
          <Container>
            <Grid className="items-center">
              <section className="col-span-12 sm:pb-8 xl:col-span-5 xl:col-start-8 xl:-mt-4 xl:pb-0">
                <h2>Competitor partner analysis</h2>
                <p>
                  See who your competitors work with and understand your relationships by viewing a publisher’s connections to those competitors.
                </p>
              </section>

              <section className="col-span-12 xl:col-span-6 xl:col-start-1 xl:row-start-1">
                <img alt="Competitor partner" src="./../images/Comp.png" className="inline-block relative" style={{width: '100%'}}/>
              </section>
            </Grid>
          </Container>
        </section>

        <section className="mb-20 px-6 md:mb-24">
          <Container>
            <Grid className="items-center">
              <section className="col-span-12 sm:pb-8 xl:col-span-5 xl:-mt-4 xl:pb-0 xxxl:col-start-2">
                <h2>Validate Leads</h2>
                <p>
                  Verify relevant leads using key metrics like the Moz Domain Authority, SimilarWeb Rank and Breezy’s own Relevancy Score, all in one place!
                </p>
              </section>

              <section className="col-span-12 xl:col-span-6 xl:col-start-7">
                <img alt="Discover potential partners" src="./../images/Validate.png" className="inline-block relative" style={{width: '100%'}}/>
              </section>
            </Grid>
          </Container>
        </section>
        <section className="px-6">
          <Container>
            <Grid className="items-center">
              <section className="col-span-12 sm:pb-8 xl:col-span-5 xl:col-start-8 xl:-mt-4 xl:pb-0">
                <h2>Recruit</h2>
                <p className="mb-12">
                  Get up-to-date and verified contact information for leads and get your outreach off to a flying start! If you’re thinking that this sounds too good to be true” then come visit us at booth 42 to see for yourself!</p>
                <p className="mb-6">
                  Or you can just pencil in a meeting with the team below, either at the event via the <strong><a className="text-blue" target="_blank" href="https://hub.performancein.live/2022/sign-in?rId=2175708">PI&nbsp;Live&nbsp;Hub</a></strong>, or using the link below.
                </p>
                <p>We’re looking forward to seeing you there!</p>
              </section>

              <section className="col-span-12 xl:col-span-6 xl:col-start-1 xl:row-start-1 relative">
                <img alt="Competitor partner" src="./../images/Recruit.png" className="inline-block relative" style={{width: '100%'}}/>
              </section>
            </Grid>
          </Container>
        </section>
        <div className="pt-20 pb-4">
          <ActionPanel>
            <h2 className="mb-6 text-4 md:mb-8 md:text-8">
              PI Live 10% Discount
            </h2>

            <p className="mb-10 md:mb-16">
              All attendees of PI Live will get an additional 10% discount on all Breezy subscriptions before the 31st November. Get in touch to book a demo before it’s too late!
            </p>

            <div className="max-w-xl mx-auto">
              <SignUpForm/>
            </div>
          </ActionPanel>
        </div>
      </section>
    </Layout>
  )
}


export const query = graphql`
  query pilive {
    mdx(fileAbsolutePath: { regex: "//static/pilive2022-index/" }) {
      frontmatter {
        title
        seo {
          description
          title
        }
      }
      body
    }
  }
`

export default PiLivePage
